type Id =
  | {
      containerId: string
      modalId?: never
    }
  | {
      containerId?: never
      modalId: string
    }

const OPEN_CLASS = "--modal-open"

export default class ElmModal {
  constructor(private id: Id) {
    this.trapFocus = this.trapFocus.bind(this)
  }

  node(): HTMLDivElement | undefined {
    if (!this.id.containerId) return

    const outerContainer =
      document.getElementById(this.id.containerId) ||
      document.body.insertBefore(
        Object.assign(document.createElement("div"), { id: this.id.containerId }),
        null
      )

    outerContainer.innerHTML = ""
    return outerContainer.appendChild(document.createElement("div"))
  }

  trapFocus(isOpen: boolean): void {
    let modal

    if (this.id.modalId) {
      modal = document.getElementById(this.id.modalId)
    } else if (this.id.containerId) {
      const elmContainer = document.getElementById(this.id.containerId)
      const modalContainer = elmContainer && elmContainer.querySelector("[aria-modal]")
      modal = modalContainer && modalContainer.firstChild
    }

    if (modal instanceof HTMLElement) {
      const onFocus = this.focusInModal(modal)

      if (isOpen) {
        document.addEventListener("focus", onFocus, true)
        document.body.classList.add(OPEN_CLASS)
      } else {
        document.removeEventListener("focus", onFocus, true)
        window.requestAnimationFrame(() => {
          if (!this.openModals().length) document.body.classList.remove(OPEN_CLASS)
        })
      }
    }
  }

  private focusInModal(modal: HTMLElement) {
    return (): void => {
      const openModals = this.openModals()
      const lastModal = openModals[openModals.length - 1]

      if (modal === lastModal && !modal.contains(document.activeElement)) {
        modal.focus()
      }
    }
  }

  private openModals(): Array<ChildNode | null> {
    return [...document.querySelectorAll("[aria-modal]:not([hidden])")].map((m) => m.firstChild)
  }
}
