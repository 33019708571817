declare global {
  interface Window {
    prefetch: { [entry: string]: Asset[] }
  }
}

type Asset = [string, "script" | "style", "anonymous" | null]

export enum ENTRY {
  BOOKINGS = "checkout_personal_details",
  SEARCH = "search",
  VENUES = "venues",
  VOUCHER_BOOKINGS = "voucher_bookings"
}

export const prefetchEntry = (entry: ENTRY): void => {
  const assets = window.prefetch && window.prefetch[entry]

  assets?.forEach(([href, as, crossOrigin]) => {
    if (document.head.querySelector(`[rel=prefetch][href="${href}"]`)) {
      return
    }

    const link = document.createElement("link")
    link.rel = "prefetch"
    link.href = href
    link.as = as
    link.crossOrigin = crossOrigin

    document.head.appendChild(link)
  })
}
