import { FuzzySearch } from "@/helpers/fuzzy_search"

interface AutocompletePorts {
  autocompleteBlurred: PortFromElm<null>
  autocompleteFocused: PortFromElm<FocusEvent>
  receiveFuse: PortToElm<AutocompleteData>
  sendFuse: PortFromElm<string>
}

const OPEN_CLASS = "--autocomplete-open"

export default ({
  app,
  data,
  onFocus
}: {
  app: ElmApp<AutocompletePorts>
  data: AutocompleteData
  onFocus?: () => void
}): void => {
  const fuzzySearch = new FuzzySearch(data)

  app.ports.autocompleteBlurred.subscribe(() => {
    document.body.classList.remove(OPEN_CLASS)
  })

  app.ports.autocompleteFocused.subscribe((e: Event) => {
    ;(<HTMLInputElement>e.target)?.select()
    document.body.classList.add(OPEN_CLASS)
    if (onFocus) onFocus()
  })

  app.ports.sendFuse.subscribe((text: string) => {
    app.ports.receiveFuse.send(fuzzySearch.search(text))
  })
}
