export default class Loader {
  private node: HTMLElement

  constructor() {
    const node = Loader.createElement("div", {
      classes: ["ui-component-loader", "variant:spinner"]
    })
    const spinner = Loader.createElement("i", {
      classes: ["far", "fa-spinner", "fa-spin", "fa-fw", "fa-3x"],
      attributes: [["aria-hidden", "true"]]
    })

    node.appendChild(spinner)

    this.node = node
  }

  static removeAll(): void {
    document.querySelectorAll(".ui-component-loader.variant\\:spinner").forEach((el) => el.remove())
  }

  start(): void {
    Loader.removeAll() // Remove any existing spinners in the DOM before adding a new one
    document.body.insertBefore(this.node, null)
  }

  stop(): void {
    this.node.remove()
  }

  private static createElement(
    tagName: keyof HTMLElementTagNameMap,
    options: { classes: string[]; attributes?: Array<[string, string]> }
  ): HTMLElement {
    const el = document.createElement(tagName)

    el.classList.add(...options.classes)
    options.attributes?.forEach(([k, v]) => el.setAttribute(k, v))

    return el
  }
}
