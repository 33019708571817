import "@/components/content/HideAndSeek"
import setupAutocompletePorts from "@/components/elm_autocomplete"
import ElmCarousel from "@/components/elm_carousel"
import { setupDatepickerPorts } from "@/components/elm_datepicker"
import ElmModal from "@/components/elm_modal"
import Loader from "@/components/loader"
import { Elm as ElmSearch } from "@/components/elm/Search/Main.elm"
import { logDecodingError } from "@/helpers/airbrake"
import { atou, utoa } from "@/helpers/base64"
import Cookies from "@/helpers/cookies"
import { pushGa4Event, saveGa4ToStorage } from "@/helpers/ga4_helper"
import { loadPictureTags } from "@/helpers/image_loader"
import { ENTRY, prefetchEntry } from "@/helpers/prefetch"

declare global {
  interface Window {
    elmSearchFlags: SearchFlags
  }
}

interface SearchFlags extends Flags {
  autocomplete: AutocompleteData
  date: { flexibility?: { value?: number } }
  lengthsOfStay: { selected: [string, string] }
  location: { selected?: { name: string } }
  path: { value: string }
}

interface SearchPorts {
  filterCountChanged: PortToElm<number>
  pathChanged: PortToElm<string>
  ready: PortFromElm<SearchFlags>
  search: PortFromElm<string>
  viewportChanged: PortToElm<null>
}

const IMAGE_SELECTOR = ".js-img"
const SEARCH_COOKIE = "search"
const DEFAULT_SEARCH_PATH = "/search"
const PAGE = Object.freeze({
  HOME: "home",
  SEARCH: "search",
  VENUE: "venue",
  ERROR: "error"
} as const)

const initCarousels = (container: HTMLElement | Document): void => {
  container.querySelectorAll<HTMLElement>(".elm-carousel").forEach((node) => {
    const { parentElement } = node
    const flags = JSON.parse(node.dataset.flags || "{}")

    new ElmCarousel({
      node,
      flags,
      ports: {
        carouselActivated: (carouselId: string): void => {
          loadPictureTags(document.querySelectorAll(`#${carouselId} ${IMAGE_SELECTOR}`))
        },
        ready: (): void => {
          loadPictureTags(parentElement?.querySelectorAll(IMAGE_SELECTOR))
        }
      }
    })
  })
}

const redirect = (path: string): void => {
  path ||= DEFAULT_SEARCH_PATH
  if (path === window.location.href.replace(window.location.origin, "")) return

  new Loader().start()
  window.location.assign(path)
}

const removeKey = (key: string, { [key as keyof object]: _, ...rest }: object): object => rest

const removeKeys = (keys: readonly string[], obj: object): object =>
  keys.reduce((acc, key) => removeKey(key, acc), obj)

const sessionFlagsExcept = (keys: string[]): Flags => {
  let session

  try {
    const cookie = atou(Cookies.get(SEARCH_COOKIE) || "")
    session = JSON.parse(decodeURIComponent(cookie))
  } catch {
    session = {}
  }

  return <Flags>removeKeys(keys, session)
}

const sessionFlags = (): Flags => sessionFlagsExcept([])

const setupSearch = (flags: SearchFlags, onAutocompleteFocus?: () => void): ElmApp<SearchPorts> => {
  const modal = new ElmModal({ containerId: "elm-search-container" })
  const app = ElmSearch.Search.Main.init({ node: modal.node(), flags })

  app.ports.flagDecodingError.subscribe(() => {
    logDecodingError(flags)
  })
  app.ports.modalOpened.subscribe(modal.trapFocus)
  app.ports.pushGa4Event.subscribe(pushGa4Event)
  app.ports.ready.subscribe((state: Flags) => {
    Cookies.set(SEARCH_COOKIE, utoa(JSON.stringify(state)))
  })
  app.ports.saveGa4ToStorage.subscribe(saveGa4ToStorage)
  app.ports.search.subscribe(redirect)

  window.addEventListener("resize", app.ports.viewportChanged.send.bind(null, null))

  setupAutocompletePorts({ app, data: flags.autocomplete, onFocus: onAutocompleteFocus })
  setupDatepickerPorts(app)

  return app
}

const addTurboFiltersHandlers = (ports: SearchPorts): void => {
  const loader = new Loader()

  const onFiltersFrameRender = (): void => {
    const appliedFilterCount = +(
      document.querySelector<HTMLElement>("[data-filter-count]")?.dataset.filterCount || 0
    )

    // Stop spinner on frame render
    loader.stop()

    // Turbo filter selections result in URL (and other) changes, so pass those back to the app
    ports.pathChanged.send(window.location.href.replace(window.location.origin, ""))
    ports.filterCountChanged.send(appliedFilterCount)
  }

  const isFiltersFrame = (target: EventTarget | null): boolean =>
    !!(target as HTMLElement)?.closest("turbo-frame#turbo-filters")

  // Start spinner on fetch request
  document.addEventListener("turbo:before-fetch-request", (e) => {
    if (isFiltersFrame(e.target)) {
      loader.start()
    }
  })

  // Handle frame render. Listen on `document` instead of `turbo-frame` so that handler will
  // persist when using back/forward buttons
  document.addEventListener("turbo:frame-render", (e) => {
    if (isFiltersFrame(e.target)) {
      onFiltersFrameRender()
    }
  })

  // Back/forward buttons need to be treated as frame render
  window.addEventListener("popstate", () => {
    // Use zero-delay `setTimeout` to ensure document state is fully ready
    // See https://developer.mozilla.org/en-US/docs/Web/API/Window/popstate_event#sect1
    window.setTimeout(onFiltersFrameRender)
  })
}

const search = (page: (typeof PAGE)[keyof typeof PAGE]): void => {
  if (!window.elmSearchFlags) return

  let flags = { ...window.elmSearchFlags, page }

  switch (page) {
    case PAGE.HOME:
    case PAGE.ERROR:
      flags = { ...flags, ...sessionFlags() }
      break
    case PAGE.VENUE:
      flags = { ...flags, ...sessionFlagsExcept(["date", "lengthsOfStay"]) }
      break
  }

  if (page === PAGE.SEARCH) {
    const app = setupSearch(flags)

    addTurboFiltersHandlers(app.ports)

    // Re-init carousels on each page load, regardless of source
    document.addEventListener("turbo:load", initCarousels.bind(null, document))
  } else {
    setupSearch(flags, prefetchEntry.bind(null, ENTRY.SEARCH))
  }
}

const initErrorSearch = search.bind(null, PAGE.ERROR)
const initHomeSearch = search.bind(null, PAGE.HOME)
const initSearch = search.bind(null, PAGE.SEARCH)
const initVenueSearch = search.bind(null, PAGE.VENUE)

export { initCarousels, initErrorSearch, initHomeSearch, initSearch, initVenueSearch }
