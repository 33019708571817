// https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem

const base64ToBytes = (base64: string): Uint8Array => {
  const binary = window.atob(base64)

  return Uint8Array.from(binary, (c) => c.codePointAt(0) || 0)
}

const bytesToBase64 = (bytes: Uint8Array): string => {
  const binary = Array.from(bytes, (x) => String.fromCodePoint(x)).join("")

  return window.btoa(binary)
}

export const utoa = (unicode: string): string => {
  const bytes = new window.TextEncoder().encode(unicode)

  return bytesToBase64(bytes)
}

export const atou = (base64: string): string => {
  const bytes = base64ToBytes(base64)

  return new TextDecoder().decode(bytes)
}
